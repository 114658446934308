import React from 'react';

export function ContactPage() {
    return <div className='container-fluid'>
        <div >
            <iframe
                className='mx-auto'
                src="https://docs.google.com/forms/d/e/1FAIpQLSf5bBvDdXWtEg_Nu9OIFHIXshXt_xDU3I7qCagsrUxYKcntPw/viewform?embedded=true"
                width="640"
                height="800" >Loading…</iframe>
        </div>
    </div>;
}