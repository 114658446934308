import React from 'react';

export function HomePage() {
    return <div className='container-fluid'>
        <div className='home-section'>
            <p className='text-content'>
                I am a Software Engineer. I have a passion for building software that is easy to use and maintainable. I love anime, open source, and learning new things. 
                Find me on <a href='https://twitter.com/pavinann'>Twitter</a>, <a href='https://www.youtube.com/bharat20110'>Youtube</a> and <a href='https://www.instagram.com/pavinann/'>Instagram</a>.
            </p>
        </div>
        <div className='mt-2'>

        </div>
    </div>;
}