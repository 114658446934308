

export const TNCPage = () => {
    return (
        <div className="m-3">
            <h1>Terms and Conditions</h1>
            <p>By using this website, you agree to the following terms and conditions:</p>

            <h2>Definitions:</h2>
            <ul>
                <li>Owner: Bellamkonda Bharat Kumar ("us", "we", "I", "me", or "our").</li>
                <li>Website: Any website or websites operated by Bellamkonda Bharat Kumar, owner.</li>
                <li>Website or websites are referred to as "this website" or "our website" or "our websites" or "the website" or "the websites" or "this site" or "our site" or "the site"</li>
            </ul>

            <h2>Terms and Conditions:</h2>
            <ul>
                <li>This website is provided as is and without any warranty or guarantee.</li>
                <li>Please do not use this website if it is illegal by the laws of your land, group, organization, or any other entity or non-entity.</li>
                <li>The owner of this website is not responsible for any damages caused by the use of this website.</li>
                <li>The owner of this website is not responsible for any damages caused by the services provided by the owner of this website.</li>
                <li>The owner of this website is not responsible for any damages caused by the use of the tools provided on this website.</li>
                <li>The owner of this website is not responsible for any damages caused by the use of the information provided on this website.</li>
                <li>The owner of this website is not responsible for any damages caused by the use of the links provided on this website.</li>
                <li>The owner of this website is not responsible for any damages caused by the use of the advertisements displayed on this website.</li>
                <li>The owner of this website is not responsible for any damages caused by the use of the third-party tools integrated with this website.</li>
                <li>The owner of this website is not responsible for any damages caused by the use of the third-party services integrated with this website.</li>
                <li>The owner of this website is not responsible for any damages caused by the use of the third-party APIs integrated with this website.</li>
                <li>The owner of this website is not responsible for any damages caused by the use of the third-party libraries integrated with this website.</li>
                <li>The owner of this website is not responsible for any damages caused by the use of the third-party software integrated with this website.</li>
                <li>The owner of this website is not responsible for any damages caused by the use of the third-party hardware integrated with this website.</li>
                <li>The owner of this website is not responsible for any damages caused by the use of the third-party content integrated with this website.</li>
                <li>The owner of this website is not responsible for any damages caused by whatever means.</li>
            </ul>
        </div>
    );
};