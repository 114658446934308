


export const AboutMePage = () => {
    return (
        <div className="m-3">
            <h1>About Me</h1>
            <p>My name is Bellamkonda Bharat Kumar and I am a software engineer.</p>
            <p>I have 8 years of experience in developing web applications, mobile applications, and desktop applications.</p>
            <p>Primarily I use the following technologies:</p>
            <ul>
                <li>.NET</li>
                <li>Azure</li>
                <li>React</li>                
                <li>SQL</li>
            </ul>
        </div>
    );
};