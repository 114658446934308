import React, { useEffect } from 'react';
import logo from './logo.svg';
import { Outlet, Link } from 'react-router-dom';
import CookieConsentComponent from './shared/components/CookieConsentComponent';



function App() {
  useEffect(() => {

    // if (!document.getElementById('cc--main')) {
    //   //@ts-ignore
    //   window.CC = window.initCookieConsent();
    //   //@ts-ignore
    //   window.CC.run({
    //     // your config (required)
    //   });
    // }

  }, []);

  return (
    <>
      <CookieConsentComponent />
      <header className='mb-2'>
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
          <div className="container-fluid">
            <a className="navbar-brand" href="#">Bellamkonda Bharat Kumar</a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link className="nav-link" aria-current="page" to="/">Home</Link>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Apps &amp; Tools
                  </a>
                  <ul className="dropdown-menu">
                    <li><Link className="dropdown-item" to="/data-cryptography">Data Cryptography</Link></li>
                    <li><Link className="dropdown-item" to="/data-hash-generator">Data Hash Generator</Link></li>
                    <li><Link className="dropdown-item" to="/compound-interest-calculator">Compound Interest Calculator</Link></li>
                    <li><hr className="dropdown-divider" /></li>
                    <li><a className="dropdown-item" href="https://onlinediff.bellamkonda.me/" target='_blank'>Online text or code diff</a></li>
                  </ul>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href='https://bharat.bellamkonda.me/' target='_blank'>Blog</a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/contact">Contact</Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <main>
        <Outlet />
      </main>
      <footer className="footer mt-auto p-3 bg-body-tertiary">

        <ul className="nav justify-content-center border-bottom pb-3 mb-3">
          <li className="nav-item"><Link to="/" className="nav-link px-2 text-body-secondary">Home</Link></li>
          <li className="nav-item"><a href='https://bharat.bellamkonda.me/' target='_blank' className="nav-link px-2 text-body-secondary">Blog</a></li>
          <li className="nav-item"><Link to="/aboutme" className="nav-link px-2 text-body-secondary">About Me</Link></li>
          <li className="nav-item"><Link to="/contact" className="nav-link px-2 text-body-secondary">Contact</Link></li>
          <li className="nav-item"><Link to="/term-and-conditions" className="nav-link px-2 text-body-secondary">Terms of use</Link></li>
          <li className="nav-item"><Link to="/refundterms" className="nav-link px-2 text-body-secondary">Refund and Cancellation Policy</Link></li>
        </ul>
        <p className="text-center text-body-secondary">&copy; {new Date().getFullYear()} Bellamkonda Bharat Kumar.</p>
      </footer>
    </>
  );
}

export default App;
