

export const RefundTermsPage = () => {
    return (
        <div className="m-3">
            <h1>Refund and Cancellation Policy</h1>
            <p>By using this website, you agree to the following refund policy:</p>

            <h2>Definitions:</h2>
            <ul>
                <li>Owner: Bellamkonda Bharat Kumar ("us", "we", "I", "me", or "our").</li>
                <li>Website: Any website or websites operated by Bellamkonda Bharat Kumar, owner.</li>
                <li>Website or websites are referred to as "this website" or "our website" or "our websites" or "the website" or "the websites" or "this site" or "our site" or "the site"</li>
            </ul>

            <h2>Refund and Cancellation:</h2>
            <ul>
                <li>Refund and Cancellation are not provided for any reason.</li>
                <li>Once the payment is made, it is final.</li>                
            </ul>
        </div>
    );
};
