import 'bootstrap';
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'vanilla-cookieconsent';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import './css/default.scss'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { HomePage } from './HomePage';
import { ContactPage } from './ContactPage';
import { AboutMePage } from './AboutMePage';
import { TNCPage } from './TNCPage';
import { RefundTermsPage } from './RefundTermsPage';

//@ts-ignore
//window.cookieconsent.initialise();

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <HomePage />
      },
      {
        path: "/contact",
        element: <ContactPage />
      },
      {
        path: "/compound-interest-calculator",
        async lazy() {
          let { CompoundInterestCalculator } = await import("./Apps/CompoundInterestCalc/CompoundInterestCalculator");
          return { element: <CompoundInterestCalculator /> }
        }
      },
      {
        path: "/data-cryptography",
        async lazy() {
          let { DataCryptographyPage } = await import("./Apps/DataCryptography/DataCryptographyPage");
          return { element: <DataCryptographyPage /> }
        }
      },
      {
        path: "/data-hash-generator",
        async lazy() {
          let { DataHashGeneratorPage } = await import("./Apps/DataHashGenerator/DataHashGeneratorPage");
          return { element: <DataHashGeneratorPage /> }
        }
      },
      {
        path: "/aboutme",
        element: <AboutMePage />
      },
      {
        path: "/term-and-conditions",
        element: <TNCPage />
      },
      {
        path: "/refundterms",
        element: <RefundTermsPage />
      }
    ]
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} fallbackElement={<div>Loading...</div>} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
